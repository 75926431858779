import React, { useMemo, useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import { Badge } from 'reactstrap';
import Selectable, { ActionMeta, Options } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { getCurrentTimestamp, groupBy } from 'helpers/utils';
import { useMasterEmployees, useSearchEmployees } from 'queries';

import { RoleMasterEmployeeResponse } from 'data/MasterEmployee/schemas/MasterEmployee.shema';

type Option = {
    label: string;
    value: string;
};

const getPositionCode = (employee: RoleMasterEmployeeResponse) =>
    employee.positioncode;

function ExportEmployeeRoles() {
    const { data: masterEmployees } = useMasterEmployees();
    const { data: searchEmployees } = useSearchEmployees();

    const [selectedPositionCodeMap, setSelectedPositionCodeMap] = useState<
        Map<string, RoleMasterEmployeeResponse>
    >(new Map());
    const [selectedEmployeeIdSet, setSelectedEmployeeIdSet] = useState<
        Set<string>
    >(new Set());

    const employeeRoleGroups = useMemo(
        () =>
            searchEmployees
                ? Array.from(
                      groupBy(searchEmployees, employee => employee.emp_id)
                  )
                : [],
        [searchEmployees]
    );

    const handleSelectEmployeeIdOption = (
        _: Options<Option>,
        actionMeta: ActionMeta<Option>
    ) => {
        setSelectedEmployeeIdSet(prevState => {
            if (actionMeta.action === 'clear') return new Set();

            const nextState = new Set(prevState);
            if (
                actionMeta.removedValue &&
                nextState.has(actionMeta.removedValue.value)
            ) {
                nextState.delete(actionMeta.removedValue.value);
                return nextState;
            }

            const option = actionMeta.option;
            if (!option) return prevState;

            return nextState.add(option.value);
        });
    };

    const handleSelectMasterEmployee = (
        _: RoleMasterEmployeeResponse[],
        actionMeta: ActionMeta<RoleMasterEmployeeResponse>
    ) => {
        setSelectedPositionCodeMap(prevState => {
            if (actionMeta.action === 'clear') return new Map();

            const nextState = new Map(prevState);
            if (
                actionMeta.removedValue &&
                nextState.has(actionMeta.removedValue.positioncode)
            ) {
                nextState.delete(actionMeta.removedValue.positioncode);
                return nextState;
            }

            const option = actionMeta.option;
            if (!option) return prevState;

            return nextState.set(option.positioncode, option);
        });
    };

    const exportToExcel = () => {
        if (!masterEmployees || !searchEmployees) return;

        const roleDescriptionsRow = [
            '',
            ...masterEmployees.map(employee => employee.positionname ?? '')
        ];
        const roleIdsRow = [
            '',
            ...masterEmployees.map(employee => employee.positioncode)
        ];

        const userHeaderRow = ['user'];

        const roleIdIndexMap = new Map(
            masterEmployees.map((employee, index) => [
                employee.positioncode,
                index
            ])
        );
        const searchEmployeeMap = groupBy(
            searchEmployees,
            employee => employee.emp_id
        );

        const employeeRoleRows = Array.from(searchEmployeeMap).map(
            ([employeeId, employeeRoles]) => {
                const row = [
                    employeeId,
                    ...Array.from({ length: masterEmployees.length }, () => '')
                ];

                employeeRoles.forEach(role => {
                    const foundRoleIdIndex = roleIdIndexMap.get(
                        role.position_code
                    );
                    if (foundRoleIdIndex) row[foundRoleIdIndex] = '/';
                });

                return row;
            }
        );

        const worksheet = XLSX.utils.aoa_to_sheet([
            roleDescriptionsRow,
            roleIdsRow,
            userHeaderRow,
            ...employeeRoleRows
        ]);

        // Styles the first and the second rows
        const range = XLSX.utils.decode_range(worksheet['!ref']!);
        for (let rowIndex = 0; rowIndex < 2; rowIndex++) {
            for (
                let columnIndex = range.s.c;
                columnIndex <= range.e.c;
                columnIndex++
            ) {
                const cellAddress = XLSX.utils.encode_cell({
                    r: rowIndex,
                    c: columnIndex
                });

                if (worksheet[cellAddress]) {
                    worksheet[cellAddress].s = {
                        font: {
                            sz: 8
                        },
                        alignment: {
                            textRotation: 90
                        }
                    } as XLSX.CellStyle;
                }
            }
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Auth Sheet');

        XLSX.writeFile(
            workbook,
            `workflow-employee-roles-${getCurrentTimestamp()}.xlsx`,
            {
                bookType: 'xlsx'
            }
        );
    };

    if (!masterEmployees || !searchEmployees) return null;

    return (
        <div className="flex h-[92.5vh] flex-col px-4">
            <button
                onClick={exportToExcel}
                className="self-end rounded-md bg-green-700 px-4 py-2 text-base font-semibold text-white active:brightness-110"
            >
                Export to Excel
            </button>

            <div className="mt-4 flex gap-4 rounded-md border p-4">
                <div className="flex-1">
                    <span className="text-base font-semibold">
                        Filter Users
                    </span>
                    <Selectable
                        isMulti
                        options={employeeRoleGroups.map(([employeeId]) => ({
                            label: employeeId,
                            value: employeeId
                        }))}
                        value={Array.from(
                            selectedEmployeeIdSet,
                            employeeId => ({
                                label: employeeId,
                                value: employeeId
                            })
                        )}
                        onChange={handleSelectEmployeeIdOption}
                    />
                </div>
                <div className="flex-1">
                    <span className="text-base font-semibold">
                        Filter Position Codes
                    </span>
                    <Selectable
                        isMulti
                        options={masterEmployees}
                        getOptionLabel={getPositionCode}
                        getOptionValue={getPositionCode}
                        value={Array.from(
                            selectedPositionCodeMap,
                            ([_, value]) => value
                        )}
                        onChange={handleSelectMasterEmployee}
                    />
                </div>
            </div>

            <div className="mt-10 grid auto-cols-min grid-cols-[auto,_1fr] gap-y-4">
                {employeeRoleGroups.map(([employeeId, roles]) => {
                    if (
                        selectedEmployeeIdSet.size > 0 &&
                        !selectedEmployeeIdSet.has(employeeId)
                    )
                        return null;

                    return (
                        <React.Fragment key={employeeId}>
                            <p className="border-b border-b-neutral-200 px-4 text-lg font-semibold">
                                {employeeId}
                            </p>

                            <div className="flex flex-wrap gap-2 border-b border-b-neutral-200 pb-4">
                                {roles.map((role, index) => (
                                    <Badge
                                        key={
                                            employeeId +
                                            role.position_code +
                                            index.toString()
                                        }
                                        color="primary"
                                        className={twMerge(
                                            'text-sm',
                                            selectedPositionCodeMap.size > 0 &&
                                                !selectedPositionCodeMap.has(
                                                    role.position_code
                                                ) &&
                                                'opacity-30'
                                        )}
                                    >
                                        {role.position_code}
                                    </Badge>
                                ))}
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}

export default ExportEmployeeRoles;
