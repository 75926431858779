export function debounce<F extends Function>(callback: F, millisec: number) {
    let timeoutId: ReturnType<typeof setTimeout>;

    return ((...args: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => callback(...args), millisec);
    }) as unknown as F;
}

export const toCapitalCase = (str: string) => {
    if (str.length === 0) {
        return '';
    }

    return str[0].toUpperCase() + str.slice(1);
};

export const safeParseJson = <T = any>(json: string) => {
    try {
        return JSON.parse(json) as T;
    } catch (error) {
        return null;
    }
};

export const getCurrentTimestamp = () => {
    const currentDate = new Date();

    return String(
        currentDate.getFullYear().toString() +
            (currentDate.getMonth() + 1).toString().padStart(2, '0') +
            currentDate.getDate().toString().padStart(2, '0') +
            currentDate.getHours().toString().padStart(2, '0') +
            currentDate.getMinutes().toString().padStart(2, '0') +
            currentDate.getSeconds().toString().padStart(2, '0')
    );
};

export const groupBy = <T, K = unknown>(
    data: T[],
    keySelector: (element: T) => K
) => {
    const map = new Map<K, T[]>();
    data.forEach(element => {
        const key = keySelector(element);
        const found = map.get(keySelector(element));
        if (!found) {
            map.set(key, [element]);
        } else {
            found.push(element);
        }
    });

    return map;
};
