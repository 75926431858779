import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { ResponseList } from 'models/Response';
import { RoleMasterEmployeeResponse } from 'data/MasterEmployee/schemas/MasterEmployee.shema';

type UseMasterEmployeesParams = {
    sid?: string;
    roleId?: string;
    employeeId?: string;
};

function useMasterEmployees(params: UseMasterEmployeesParams = {}) {
    const { sid, roleId, employeeId } = params;

    return useQuery({
        queryKey: ['master-employee', params],
        queryFn: () =>
            axios.get<ResponseList<RoleMasterEmployeeResponse>>(
                '/v1/Employee/Get',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'WF_APPLICATION'
                        )}`
                    },
                    params: {
                        sid,
                        role_id: roleId,
                        emp_id: employeeId
                    }
                }
            ),
        select: response =>
            response.data.result_list.filter(employee => employee.positioncode)
    });
}

export default useMasterEmployees;
