import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { ResponseList } from 'models/Response';

export type SearchEmployee = {
    position_code: string;
    position_name: string;
    emp_id: string;
    emp_name: string;
    valid_from: string;
    valid_to: string;
    is_delegate: string;
    delegate_from_emp_id: string;
};

type useSearchEmployeesParams = {
    sid?: string;
    positionCode?: string;
};

function useSearchEmployees(params: useSearchEmployeesParams = {}) {
    return useQuery({
        queryKey: ['search-employee'],
        queryFn: () =>
            axios.get<ResponseList<SearchEmployee>>('/v2/Employee/Search', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'WF_APPLICATION'
                    )}`
                },
                params
            }),
        select: response => response.data.result_list
    });
}

export default useSearchEmployees;
